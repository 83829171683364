<template>
    <nav v-if="$route.path !== '/'" class="c-breadcrumbs">
        <ul>
            <li v-for="(crumb, index) in breadcrumbs" :key="index">
                <NuxtLink
                    v-if="index !== breadcrumbs.length - 1"
                    :to="crumb.path"
                    :title="crumb.path == '/' ? 'Home' : crumb.label">
                    <span v-html="crumb.label"></span>
                </NuxtLink>

                <span v-else-if="page_title" v-html="page_title"></span>

                <span v-else v-html="crumb.label"></span>
            </li>
        </ul>
    </nav>
</template>

<script setup>
    const props = defineProps({
        page_title: {
            type: String,
            required: false,
        },
    });

    const route = useRoute();

    // console.log("Route: ", route);

    const breadcrumbs = computed(() => {
        const pathArray = route.path.split("/").filter(Boolean);
        let breadcrumbTrail = [];
        let path = "";

        // Always start with home route
        breadcrumbTrail.push({
            path: "/",
            label: '<i class="fa-sharp fa-regular fa-house" title="Home" aria-hidden="true"></i>',
        });

        // Build out breadcrumbs for each segment of the route
        pathArray.forEach((segment, index) => {
            if (segment === "category") {
                return;
            }

            if (segment === "homes-for-sale") {
                path += "/holiday-home-ownership";
            } else {
                path += `/${segment}`;
            }

            breadcrumbTrail.push({
                path: path + "/",
                label: segment.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
            });
        });

        return breadcrumbTrail;
    });
</script>
